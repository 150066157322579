function runScript() {
    async function runUpd() {
          // Select all script tags on the page
          const scriptTags = document.querySelectorAll('script[type="text/javascript"]')
  
          const elements = [...document.querySelectorAll('dt')].filter(dt =>
              dt.textContent === 'Lot Size (sqft)'
          )
      
          // Loop through each script tag to find the one containing the JSON data
          for (let i = 0; i < scriptTags.length; i++) {
              const scriptText = scriptTags[i].textContent
      
              // Check if the script text contains the JSON data
              if (scriptText.indexOf('realgeeks.analytics.send_activity') !== -1) {
                  // Extract the JSON data from the script text content
                  const jsonData = scriptText.match(/realgeeks.analytics.send_activity\((.*?)\)/)[1]
      
                  // Parse the JSON data
                  let propertyData = JSON.parse(jsonData).property
      
      
                  if (!propertyData.sqft && elements.length) {
                      let element = elements[0]
                      let sqft = element.nextElementSibling.textContent
                      if (sqft) {
                          propertyData.sqft = sqft
                      }
                  }
      
                  if (!propertyData.images) {
                      const slideshowElement = document.querySelector('.slideshow')
                      if (slideshowElement) {
                          const firstImageElement = slideshowElement.querySelector('.fotorama__stage__frame img')
                          if (firstImageElement) {
                              const imageUrl = firstImageElement.getAttribute('src')
                              propertyData.images = [imageUrl]
                          }
                      }
                  }
      
                  if (!propertyData.listing_price) {
                      const priceElement = document.querySelector('dd.price')
      
                      if (priceElement) {
                          const textContent = priceElement.textContent
                          const match = textContent.match(/\$([\d,]+)/)
      
                          if (match) {
                              // Extract the numerical value and remove commas
                              const numericalValue = match[1].replace(/,/g, '')
      
                              // Convert the numerical value to a number
                              const numericPrice = parseFloat(numericalValue)
      
                              propertyData.listing_price = numericPrice
                          }
                      }
                  }
      
                  await fetch(`${process.env.APP_URL}/upd`, {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json'
                      },
                      body: JSON.stringify({ propertyData })
                  })
      
                  break;
              }
          }
    }
  
    // Check if the DOM is already loaded
    if (document.readyState === 'complete' || document.readyState === 'interactive') {
      runUpd()
    } else {
      // If the DOM is not yet loaded, wait for the DOMContentLoaded event
      document.addEventListener('DOMContentLoaded', function () {
        runUpd()
      });
    }
  }
  
  runScript();
  